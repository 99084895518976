
<template>
    <v-container class="fill-height" v-if="mounted">
        <v-window v-model="page" class="fill-height" style="width:100%">
            <v-window-item value="page-0" class="fill-height">
                <v-container class="fill-height">
                    <v-row justify="center">
                        <v-col cols="12" align="center">
                            <v-progress-circular :size="50" color="cyan" indeterminate></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-window-item>
            <v-window-item value="page-1">
                <v-card flat width="800" max-width="100vw" class="mx-auto">
                    <v-card-title>
                        Hallo, {{ peerReview.firstName }}
                    </v-card-title>
                    <v-card-text>
                        <p>Heel fijn dat jij deze vragenlijst wilt invullen.</p>
                        <p>Dit zal {{ peerReview.coachee.name }} helpen om een beter zicht te krijgen op het eigen
                            gedrag, de eigen
                            voorkeuren, behoeftes en valkuilen.</p>

                        <p>In de vragenlijst word je gevraagd om een 40-tal gedragingen te scoren op de mate van
                            observeerbaarheid van dat gedrag. Dat wil zeggen dat je kan aanduiden in welke mate het gedrag
                            toegepast wordt door de persoon die je scoort.</p>

                        <p>Bij elke gedragsvoorkeur zal je een gedragsomschrijving vinden.
                            Per gedragsvoorkeur duid je aan in welke mate je deze herkent bij de persoon.
                            Dit doe je door de mate van ontwikkeling aan te duiden. Ontwikkeld gedrag wordt meer getoond dan
                            niet ontwikkeld gedrag.</p>

                        <p>
                        <ul>
                            <li>Klik het bolletje 'Hoog' aan als je dit gedrag uitgesproken ziet bij de ander.</li>
                            <li>Klik het bolletje 'Medium' aan als je vindt dat je dit gedrag af en toe ziet bij de ander.
                            </li>
                            <li>Klik het bolletje 'Laag' aan als dit gedrag is dat jij niet ziet bij de ander.</li>
                        </ul>
                        </p>

                        <p>Je vindt bij elke gedragsomschrijving ook een vak waar je een opmerking kan noteren. Dit is omdat
                            gedrag nooit op zichzelf staat, maar verbonden is aan de context en de omstandigheden waarin het
                            gesteld wordt. In dit vak mag je dus zeker beschrijven in welke context je het gedrag ziet en in
                            welke niet.
                        </p>

                        <P>Deze vragenlijst is realtime. Elk antwoord wordt onmiddellijk opgeslaan en is zichtbaar voor de coachee.

                        </P>
                        <p>
                            <a href="https://fulfil.be/consent" target="_blank">Privacy</a>
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn class="primary" @click="page = 'page-2'">Ga naar het interview</v-btn>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
            </v-window-item>
            <v-window-item value="page-2">
                <h3>PEER REVIEW</h3>
                <p>{{ peerReview.firstName }} &nbsp; {{ peerReview.lastName }}</p>
                <v-divider />
                <v-card v-for="q in peerReview.questions" :key="q.questionId" class="my-8 mx-auto" max-width="900" outlined>
                    <v-card-text>
                        <v-row>
                            <v-col cols="9">
                                <span class="text-subtitle-1">{{ q.position }}. {{ q.title ? q.title : '' }}</span>
                            </v-col>
                            <v-col cols="3" align="right">
                                <v-icon v-show="q.latency" color="success">mdi-check-bold</v-icon>
                            </v-col>
                        </v-row>
                        <v-row justify="space-around">
                            <v-col cols="12" lg="6">{{ q.question }}
                            </v-col>
                            <v-col cols="12" lg="5">
                                <p> Mate van ontwikkeling </p>
                                <v-radio-group v-model="q.latency" @change="saveAnswer()" row>
                                    <v-radio v-for="item in radioItems" :key="item.value" :value="item.value"
                                        :label="item.text"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row justify="space-around">
                            <v-col cols="12" lg="6">
                                <v-textarea outlined v-model="q.context" @input="saveAnswer()" label="jouw opmerking" />
                            </v-col>
                            <v-col cols="5" />
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-sheet class="my-8 mx-auto success--text" max-width="900" >
                    <p>Bedankt voor het invullen van deze vragenlijst. De antwoorden worden automatisch bewaard.</p>
                </v-sheet>
                
            </v-window-item>
        </v-window>
    </v-container>
</template>

<script>
import { functions } from "@/services/firebase";
import { httpsCallable } from "firebase/functions";
export default {
    name: "PeerReview",
    data() {
        return {
            loading: false,
            mounted: false,
            page: "page-0",
            peerReview: {},
            link: '',
            radioItems: [
                { text: "Laag", value: 1 },
                { text: "Medium", value: 2 },
                { text: "Hoog", value: 3 },
            ],
        }
    },
    mounted() {
        this.getPeerReview()
    },
    methods: {
        async getPeerReview() {
            var self = this;
            this.loading = true
            try {
                const urlParams = new URLSearchParams(window.location.search);
                this.link = urlParams.get('link')
                const getPeerData = httpsCallable(functions, 'getPeerData');
                const peerData = await getPeerData({ link: this.link })
                this.peerReview = peerData.data
                this.mounted = true
                //console.log(this.peerReview)
                /*
                                window.setTimeout(function () {
                                    self.page = 1
                                }, 15000)
                */
                self.page = "page-1"
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
            }
        },
        async saveAnswer(q) {
            const updatePeerData = httpsCallable(functions, 'updatePeerData');
            await updatePeerData({
                data: {
                    link: this.link,
                    data: this.peerReview
                }
            })
        },
    }
}
</script>
